import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import Features from '../components/features';
import Layout from '../components/layout';
import Trial from '../components/trial';

import {PricingData, PricingHelpers} from '../data/pricing';

const Pricing = () => {
    const standardFeatures = [
        {"name": "Custom Domain Name", "description": 
            <>Bring your own custom domain name to <span class="text-primary fw-bold">Nitro</span>. If you don't have one, your application will use a provided domain, <strong>nitroapp.io</strong></>}
    ];

    const tiers = {
        "basic": {
            "name": "Basic",
            "description": "Good for small, low traffic sites",
            "details": [
                "Shared CPU",
                "Shared Database",
                "3 Daily Snapshots"
            ],
        },
        "power": {
            "name": "Power",
            "description": "Best all around",
            "details": [
                "Shared CPU",
                "Dedicated Database",
                "7 Daily Snapshots"
            ],
            "ribbon": true
        },
        "turbo": {
            "name": "Turbo",
            "description": "Best for high traffic sites",
            "details": [
                "Dedicated CPU",
                "Dedicated Database",
                "7 Daily Snapshots"
            ],
        },
        /*"elite": {
            "name": "Elite",
            "description": "High performance tier",
            "details": [
                "Dedicated CPU",
                "Dedicated Database",
                "Auto Scaling"
            ],
        }*/
    };

    const pricing = PricingData;
    const appProviders = useStaticQuery(graphql`
    query {
        allNitroApplicationProvider {
            edges {
              node {
                id
                name
                plans {
                  id
                  name
                  priceBilledMonthly
                  features {
                    name
                  }
                }
              }
            }
        }
    }
  `).allNitroApplicationProvider.edges.map(e => e.node);

    return (
        <Layout>
            <section class="bg-half bg-light d-table w-100">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-12 text-center">
                            <div class="page-next-level">
                                <h4 class="title"> Pricing </h4>
                                <div class="page-next">
                                    <nav aria-label="breadcrumb" class="d-inline-block">
                                        <ul class="breadcrumb bg-white rounded shadow mb-0">
                                            <li class="breadcrumb-item"><a href="/">Home</a></li>
                                            <li class="breadcrumb-item active" aria-current="page">Pricing</li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div> 
            </section>

            <div class="position-relative">
                <div class="shape overflow-hidden text-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section class="section">
        {/*
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12 text-center">
                            <div class="section-title mb-4 pb-2">
                                <h4 class="title mb-4">Pricing #1</h4>
                                <p class="text-muted para-desc mb-0 mx-auto">Start working with <span class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness, drive traffic, connect.</p>
                            </div>
                        </div>
                    </div>

                    <div class="row align-items-center">
                        <div class="col-md-4 col-12 mt-4 pt-2">
                            <div class="card pricing-rates business-rate shadow bg-light rounded text-center border-0">
                                <div class="card-body py-5">
                                    <h6 class="title fw-bold text-uppercase text-primary mb-4">Free</h6>
                                    <div class="d-flex justify-content-center mb-4">
                                        <span class="h4 mb-0 mt-2">$</span>
                                        <span class="price h1 mb-0">0</span>
                                        <span class="h4 align-self-end mb-1">/mo</span>
                                    </div>
                                    
                                    <ul class="list-unstyled mb-0 ps-0">
                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Full Access</li>
                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Enhanced Security</li>
                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Source Files</li>
                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>1 Domain Free</li>
                                    </ul>
                                    <a href="javascript:void(0)" class="btn btn-primary mt-4">Buy Now</a>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-md-4 col-12 mt-4 pt-2">
                            <div class="card pricing-rates business-rate shadow bg-light rounded text-center border-0">
                                <div class="ribbon ribbon-right ribbon-warning overflow-hidden"><span class="text-center d-block shadow small h6">Best</span></div>
                                <div class="card-body py-5">
                                    <h6 class="title fw-bold text-uppercase text-primary mb-4">Starter</h6>
                                    <div class="d-flex justify-content-center mb-4">
                                        <span class="h4 mb-0 mt-2">$</span>
                                        <span class="price h1 mb-0">39</span>
                                        <span class="h4 align-self-end mb-1">/mo</span>
                                    </div>

                                    <ul class="list-unstyled mb-0 ps-0">
                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Full Access</li>
                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Source Files</li>
                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Free Appointments</li>
                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Free Installment</li>
                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Enhanced Security</li>
                                    </ul>
                                    <a href="javascript:void(0)" class="btn btn-primary mt-4">Get Started</a>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-md-4 col-12 mt-4 pt-2">
                            <div class="card pricing-rates business-rate shadow bg-light rounded text-center border-0">
                                <div class="card-body py-5">
                                    <h6 class="title fw-bold text-uppercase text-primary mb-4">Professional</h6>
                                    <div class="d-flex justify-content-center mb-4">
                                        <span class="h4 mb-0 mt-2">$</span>
                                        <span class="price h1 mb-0">59</span>
                                        <span class="h4 align-self-end mb-1">/mo</span>
                                    </div>
                                    
                                    <ul class="list-unstyled mb-0 ps-0">
                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Full Access</li>
                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Enhanced Security</li>
                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Source Files</li>
                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>1 Domain Free</li>
                                    </ul>
                                    <a href="javascript:void(0)" class="btn btn-primary mt-4">Try It Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        */}

            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 text-center">
                        <div class="section-title mb-4 pb-2">
                            <a id="standard-features"></a>
                            <h4 class="title mb-4">Standard Features</h4>
                            <p class="text-muted para-desc mx-auto mb-0">The features below are standard and included in every plan.</p>
                        </div>
                    </div>
                </div>

                <Features/>

                <div class="row justify-content-center mt-4">
                    <div class="col-12 text-center">
                        <div class="section-title mb-4 pb-2">
                            <h2 class="title mb-4">Pricing Plans</h2>
                            <p class="text-muted para-desc mx-auto mb-0">See below for a full list of pricing for each application. Application pricing varies based on the resources required to run it.</p>
                        </div>
                    </div>
                </div>

                <a id="plans"></a>
{/*
                <div class="row">
                    {standardFeatures.map(feature => (
                        <div class="col-lg-4 col-md-6 mt-4 pt-2">
                            <div>
                                    <h4 class="title mb-0">{feature.name}</h4>
                            <p class="text-muted mb-0">With <span class="text-primary fw-bold">Nitro</span>, all
                                applications are fully managed. We handle keeping your software up to date, secure, and
                                fast. </p>
                                </div>
                        </div>
                    ))}
                    {standardFeatures.map(feature => (
                        <div class="col-lg-4 col-md-6 mt-4 pt-2">
                            <div class="d-flex key-feature align-items-center p-3 rounded shadow">
                                <div class="icon text-center rounded-circle me-3">
                                    <i data-feather="monitor" class="fea icon-ex-md text-primary"></i>
                                </div>
                                <div class="">
                                    <h4 class="title mb-0">{feature.name}</h4>
                            <p class="text-muted mb-0">With <span class="text-primary fw-bold">Nitro</span>, all
                                applications are fully managed. We handle keeping your software up to date, secure, and
                                fast. </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                    */}
            </div>

        {appProviders.map(p => (
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12 text-center">
                            <div class="section-title pb-2">
                                <h4 class="title">{p.name}</h4>
                                {/*
                                    <p class="text-muted para-desc mb-0 mx-auto">Start working with <span class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness, drive traffic, connect.</p>
                                */}
                            </div>
                        </div>
                    </div>

            <div class="row justify-content-center">
                {p.plans.map(plan => (
                    <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                        <div class="card pricing-rates business-rate shadow bg-light border-0 rounded">
                            {tiers[plan.id].ribbon ? 
                            <div class="ribbon ribbon-right ribbon-warning overflow-hidden"><span
                                    class="text-center d-block shadow small h6">Best</span></div>
                            : ''}
                            <div class="card-body">
                                <h6 class="title fw-bold text-uppercase text-primary mb-4">{tiers[plan.id].name}</h6>

                                <div class="d-flex mb-4">
                                    <span class="h4 mb-0 mt-2">$</span>
                                    <span class="price h1 mb-0">{plan.priceBilledMonthly}</span>
                                    <span class="h4 align-self-end mb-1">/month</span>
                                    <span class="h4 align-self-end mb-1"></span>
                                </div>

                                <div class="d-flex mb-4">
                                    <span class="h5 align-self-end mb-1">{tiers[plan.id].description}</span>
                                </div>

                                <ul class="list-unstyled mb-0 ps-0">
                                    {plan.features.map(d => (
                                    <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i
                                                class="uil uil-check-circle align-middle"></i></span>{d.name}</li>
                                    ))}
                                </ul>

                            </div>
                        </div>
                    </div>
                ))}

{/*
                <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                    <div class="card pricing-rates business-rate shadow bg-light border-0 rounded">
                        <div class="card-body">
                            <h6 class="title fw-bold text-uppercase text-primary mb-4">Ghost</h6>
                            <span>Starting at </span>
                            <div class="d-flex mb-4">
                                <span class="h4 mb-0 mt-2">$</span>
                                <span class="price h1 mb-0">5</span>
                                <span class="h4 align-self-end mb-1">/month</span>
                                <span class="h4 align-self-end mb-1"></span>
                                <span>($0.0069/hr)</span>
                            </div>

                            <ul class="list-unstyled mb-0 ps-0">
                                <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i
                                            class="uil uil-check-circle align-middle"></i></span>Free SSL</li>
                                <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i
                                            class="uil uil-check-circle align-middle"></i></span>Custom Domain Name</li>
                                <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i
                                            class="uil uil-check-circle align-middle"></i></span>Daily Backups</li>
                                <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i
                                            class="uil uil-check-circle align-middle"></i></span>Fully Managed</li>
                                <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i
                                            class="uil uil-check-circle align-middle"></i></span>Nitro CDN</li>
                            </ul>
                            <a href="javascript:void(0)" class="btn btn-primary mt-4">More Info</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                    <div class="card pricing-rates business-rate shadow bg-light border-0 rounded">
                        <div class="card-body">
                            <h6 class="title fw-bold text-uppercase text-primary mb-4">Discourse</h6>
                            <span>Starting at </span>
                            <div class="d-flex mb-4">
                                <span class="h4 mb-0 mt-2">$</span>
                                <span class="price h1 mb-0">10</span>
                                <span class="h4 align-self-end mb-1">/month</span>
                                <span class="h4 align-self-end mb-1"></span>
                                <span>($0.0138/hr)</span>
                            </div>

                            <ul class="list-unstyled mb-0 ps-0">
                                <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i
                                            class="uil uil-check-circle align-middle"></i></span>Free SSL</li>
                                <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i
                                            class="uil uil-check-circle align-middle"></i></span>Custom Domain Name</li>
                                <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i
                                            class="uil uil-check-circle align-middle"></i></span>Daily Backups</li>
                                <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i
                                            class="uil uil-check-circle align-middle"></i></span>Fully Managed</li>
                                <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i
                                            class="uil uil-check-circle align-middle"></i></span>Nitro CDN</li>
                            </ul>
                            <a href="javascript:void(0)" class="btn btn-primary mt-4">More Info</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                    <div class="card pricing-rates business-rate shadow bg-light border-0 rounded">
                        <div class="card-body">
                            <h6 class="title fw-bold text-uppercase text-primary mb-4">Wiki.js</h6>
                            <span>Starting at </span>
                            <div class="d-flex mb-4">
                                <span class="h4 mb-0 mt-2">$</span>
                                <span class="price h1 mb-0">5</span>
                                <span class="h4 align-self-end mb-1">/month</span>
                                <span class="h4 align-self-end mb-1"></span>
                                <span>($0.0069/hr)</span>
                            </div>

                            <ul class="list-unstyled mb-0 ps-0">
                                <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i
                                            class="uil uil-check-circle align-middle"></i></span>Free SSL</li>
                                <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i
                                            class="uil uil-check-circle align-middle"></i></span>Custom Domain Name</li>
                                <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i
                                            class="uil uil-check-circle align-middle"></i></span>Daily Backups</li>
                                <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i
                                            class="uil uil-check-circle align-middle"></i></span>Fully Managed</li>
                                <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i
                                            class="uil uil-check-circle align-middle"></i></span>Nitro CDN</li>
                            </ul>
                            <a href="javascript:void(0)" class="btn btn-primary mt-4">More Info</a>
                        </div>
                    </div>
</div>*/}
            </div>

                    <div class="row align-items-center pb-4">
                        <div class="col-12 mt-4 pt-2">
                            {/*<div class="text-center">
                                <ul class="nav nav-pills rounded-pill justify-content-center d-inline-block border py-1 px-2" id="pills-tab" role="tablist">
                                    <li class="nav-item d-inline-block">
                                        <a class="nav-link px-3 rounded-pill active monthly" id="Monthly" data-bs-toggle="pill" href="#Month" role="tab" aria-controls="Month" aria-selected="true">Monthly</a>
                                    </li>
                                    <li class="nav-item d-inline-block">
                                        <a class="nav-link px-3 rounded-pill yearly" id="Yearly" data-bs-toggle="pill" href="#Year" role="tab" aria-controls="Year" aria-selected="false">Yearly <span class="badge rounded-pill bg-success">15% Off </span></a>
                                    </li>
                                </ul>
                            </div>

                            <div class="tab-content" id="pills-tabContent">
                                <div class="tab-pane fade active show" id="Month" role="tabpanel" aria-labelledby="Monthly">    
                                    <div class="row">
                                        <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                                            <div class="card pricing-rates business-rate shadow bg-light border-0 rounded">
                                                <div class="card-body">
                                                    <h6 class="title fw-bold text-uppercase text-primary mb-4">Free</h6>
                                                    <div class="d-flex mb-4">
                                                        <span class="h4 mb-0 mt-2">$</span>
                                                        <span class="price h1 mb-0">0</span>
                                                        <span class="h4 align-self-end mb-1">/mo</span>
                                                    </div>
                                                    
                                                    <ul class="list-unstyled mb-0 ps-0">
                                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Full Access</li>
                                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Source Files</li>
                                                    </ul>
                                                    <a href="javascript:void(0)" class="btn btn-primary mt-4">Buy Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                                            <div class="card pricing-rates business-rate shadow bg-white border-0 rounded">
                                                <div class="ribbon ribbon-right ribbon-warning overflow-hidden"><span class="text-center d-block shadow small h6">Best</span></div>
                                                <div class="card-body">
                                                    <h6 class="title fw-bold text-uppercase text-primary mb-4">Starter</h6>
                                                    <div class="d-flex mb-4">
                                                        <span class="h4 mb-0 mt-2">$</span>
                                                        <span class="price h1 mb-0">39</span>
                                                        <span class="h4 align-self-end mb-1">/mo</span>
                                                    </div>
                    
                                                    <ul class="list-unstyled mb-0 ps-0">
                                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Full Access</li>
                                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Source Files</li>
                                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Free Appointments</li>
                                                    </ul>
                                                    <a href="javascript:void(0)" class="btn btn-primary mt-4">Get Started</a>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                                            <div class="card pricing-rates business-rate shadow bg-light border-0 rounded">
                                                <div class="card-body">
                                                    <h6 class="title fw-bold text-uppercase text-primary mb-4">Professional</h6>
                                                    <div class="d-flex mb-4">
                                                        <span class="h4 mb-0 mt-2">$</span>
                                                        <span class="price h1 mb-0">59</span>
                                                        <span class="h4 align-self-end mb-1">/mo</span>
                                                    </div>
                                                    
                                                    <ul class="list-unstyled mb-0 ps-0">
                                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Full Access</li>
                                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Source Files</li>
                                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>1 Domain Free</li>
                                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Enhanced Security</li>
                                                    </ul>
                                                    <a href="javascript:void(0)" class="btn btn-primary mt-4">Try It Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                                            <div class="card pricing-rates business-rate shadow bg-light border-0 rounded">
                                                <div class="card-body">
                                                    <h6 class="title fw-bold text-uppercase text-primary mb-4">Ultimate</h6>
                                                    <div class="d-flex mb-4">
                                                        <span class="h4 mb-0 mt-2">$</span>
                                                        <span class="price h1 mb-0">79</span>
                                                        <span class="h4 align-self-end mb-1">/mo</span>
                                                    </div>
                                                    
                                                    <ul class="list-unstyled mb-0 ps-0">
                                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Full Access</li>
                                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Enhanced Security</li>
                                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Source Files</li>
                                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>1 Domain Free</li>
                                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Free Installment</li>
                                                    </ul>
                                                    <a href="javascript:void(0)" class="btn btn-primary mt-4">Started Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
        
                                <div class="tab-pane fade" id="Year" role="tabpanel" aria-labelledby="Yearly">
                                    <div class="row">
                                        <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                                            <div class="card pricing-rates business-rate shadow bg-light border-0 rounded">
                                                <div class="card-body">
                                                    <h6 class="title fw-bold text-uppercase text-primary mb-4">Free</h6>
                                                    <div class="d-flex mb-4">
                                                        <span class="h4 mb-0 mt-2">$</span>
                                                        <span class="price h1 mb-0">10</span>
                                                        <span class="h4 align-self-end mb-1">/mo</span>
                                                    </div>
                                                    
                                                    <ul class="list-unstyled mb-0 ps-0">
                                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Full Access</li>
                                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Source Files</li>
                                                    </ul>
                                                    <a href="javascript:void(0)" class="btn btn-primary mt-4">Buy Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                                            <div class="card pricing-rates business-rate shadow bg-white border-0 rounded">
                                                <div class="ribbon ribbon-right ribbon-warning overflow-hidden"><span class="text-center d-block shadow small h6">Best</span></div>
                                                <div class="card-body">
                                                    <h6 class="title fw-bold text-uppercase text-primary mb-4">Starter</h6>
                                                    <div class="d-flex mb-4">
                                                        <span class="h4 mb-0 mt-2">$</span>
                                                        <span class="price h1 mb-0">139</span>
                                                        <span class="h4 align-self-end mb-1">/mo</span>
                                                    </div>
                    
                                                    <ul class="list-unstyled mb-0 ps-0">
                                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Full Access</li>
                                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Source Files</li>
                                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Free Appointments</li>
                                                    </ul>
                                                    <a href="javascript:void(0)" class="btn btn-primary mt-4">Get Started</a>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                                            <div class="card pricing-rates business-rate shadow bg-light border-0 rounded">
                                                <div class="card-body">
                                                    <h6 class="title fw-bold text-uppercase text-primary mb-4">Professional</h6>
                                                    <div class="d-flex mb-4">
                                                        <span class="h4 mb-0 mt-2">$</span>
                                                        <span class="price h1 mb-0">159</span>
                                                        <span class="h4 align-self-end mb-1">/mo</span>
                                                    </div>
                                                    
                                                    <ul class="list-unstyled mb-0 ps-0">
                                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Full Access</li>
                                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Source Files</li>
                                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>1 Domain Free</li>
                                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Enhanced Security</li>
                                                    </ul>
                                                    <a href="javascript:void(0)" class="btn btn-primary mt-4">Try It Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                                            <div class="card pricing-rates business-rate shadow bg-light border-0 rounded">
                                                <div class="card-body">
                                                    <h6 class="title fw-bold text-uppercase text-primary mb-4">Ultimate</h6>
                                                    <div class="d-flex mb-4">
                                                        <span class="h4 mb-0 mt-2">$</span>
                                                        <span class="price h1 mb-0">179</span>
                                                        <span class="h4 align-self-end mb-1">/mo</span>
                                                    </div>
                                                    
                                                    <ul class="list-unstyled mb-0 ps-0">
                                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Full Access</li>
                                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Enhanced Security</li>
                                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Source Files</li>
                                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>1 Domain Free</li>
                                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Free Installment</li>
                                                    </ul>
                                                    <a href="javascript:void(0)" class="btn btn-primary mt-4">Started Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>    
                            </div>*/}
                        </div>
                            </div>
                </div>
        ))}
                

                <div class="row justify-content-center mt-4">
                    <div class="col-12 text-center">
                        <div class="section-title mb-4 pb-2">
                            <a id="snapshots"></a><h1 class="title mb-4">Snapshot Pricing</h1>
                            <p class="para-desc mx-auto mb-0">
                                Automated, daily snapshots are free of charge and included in your plan. Manual snapshots up to <strong>10GB</strong> per site are included. Anything above 10GB will be billed at <strong>$0.05/GB/month</strong>.
                            </p>
                        </div>
                    </div>
                </div>
                
            </section>

            <section class="section bg-light">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 col-12">
                            <div class="d-flex">
                                <i data-feather="help-circle" class="fea icon-ex-md text-primary me-2 mt-1"></i>
                                <div class="flex-1">
                                    <h5 class="mt-0">How does the metered billing work?</h5>
                                    <p class="answer text-muted mb-0">
                                        With Nitro, you only pay for what you use (rounded up to the hour). Your application usage is tracked and regardless of whether you run for 1 hour or the whole month -- you only pay for what you use.
                                    </p>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div class="d-flex">
                                <i data-feather="help-circle" class="fea icon-ex-md text-primary me-2 mt-1"></i>
                                <div class="flex-1">
                                    <h5 class="mt-0">Can a plan be upgraded?</h5>
                                    <p class="answer text-muted mb-0">You can always upgrade your application to a higher tier plan. Downgrading to a cheaper plan is only allowed if you are using less storage than the new plan.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Trial/>
                </div>
            </section>
        </Layout>
    );
}

export default Pricing;